import React, { Component } from 'react';
import jetskiTrees from "../../assets/images/about/jetskiTrees.jpg";
const Emoji = props => (
    <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.symbol}
    </span>
);

class About extends Component {
    render() {
        return (
            <section>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-lg-6 mb-6 mb-lg-0">
                            <img src={jetskiTrees} alt="Image" className="img-fluid rounded-borders" />
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">
                            <div> <span className="badge badge-primary-soft p-2">
                                <i className="la la-exclamation ic-3x rotation" />
                            </span>
                            <h2 className="mt-3"><Emoji symbol="💨" label="gas"/>Powerful jetskis up to 3 seats each</h2>
                            <p className="lead">Must complete a test and waiver to ride it legally.</p>
                            </div>
                            <div className="d-flex flex-wrap justify-content-start">
                            <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                                <div className="d-flex align-items-center">
                                <div className="badge-primary-soft rounded p-1">
                                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                </div>
                                <p className="mb-0 ml-3">1 hour = 140$</p>
                                </div>
                            </div>
                            <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                                <div className="d-flex align-items-center">
                                <div className="badge-primary-soft rounded p-1">
                                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                </div>
                                <p className="mb-0 ml-3">2 hours = 220$</p>
                                </div>
                            </div>
                            <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                                <div className="d-flex align-items-center">
                                <div className="badge-primary-soft rounded p-1">
                                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                </div>
                                <p className="mb-0 ml-3">3 hours = 300$</p>
                                </div>
                            </div>
                            <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                                <div className="d-flex align-items-center">
                                <div className="badge-primary-soft rounded p-1">
                                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                </div>
                                <p className="mb-0 ml-3">4 hours = 380$</p>
                                </div>
                            </div>
                            <div className="mb-3 mr-4 ml-lg-0 mr-lg-4">
                                <div className="d-flex align-items-center">
                                <div className="badge-primary-soft rounded p-1">
                                    <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12" />
                                    </svg>
                                </div>
                                <p className="mb-0 ml-3">5 hours = 460$</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
        );
    }
}

export default About;