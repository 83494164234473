import React, { Component } from 'react';
import locationIcon from "../../assets/images/hero/locationIcon.png";
import cashIcon from "../../assets/images/hero/cashIcon.png";
import lifeVestIcon from "../../assets/images/hero/lifeVestIcon.png";
import carIcon from "../../assets/images/hero/carIcon.png";
import droneIcon from "../../assets/images/hero/droneIcon.png";

const Emoji = props => (
    <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.symbol}
    </span>
);


class Feature extends Component {
    render() {
        return (
            <section className="text-center p-0">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-xl-6 col-lg-4 mb-8 mb-lg-0">
                <div className="px-4 py-7 rounded hover-translate">
                    <div>
                    <img src={locationIcon} alt="Image" className="img-fluid icon-feature" />

                    </div>
                    <h5 className="mt-4 mb-3"><Emoji symbol="🌴" label="palm"/>Locations available</h5>
                    <p className="mb-0">1. Lake Brantley, Longwood, FL, United States. <br /> 25$ fee</p><br />
                    <p className="mb-0">2. Lake Maitland, Winterpark, FL, United States. <br /> 35$ fee</p><br />
                    <p className="mb-0">3. Tampa Bay, Holmes Beach, FL, United States. <br /> 150$ fee</p><br />                 </div>
                    
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="px-4 py-7 rounded hover-translate">
                    <div>
                    <img src={cashIcon} alt="Image" className="img-fluid icon-feature" />
                    </div>
                    <h5 className="mt-4 mb-3">Payment and registration</h5>
                    <p className="mb-0"> <Emoji symbol="💵" label="money"/>
                        Accepting cash / credit cards only. Be ID ready. 18+ years</p>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                  
                <div className="px-4 py-7 rounded hover-translate bg-primary-soft">
                    <div>
                        {/* insert lifejacket svg here with same attributes */}
                        <img src={lifeVestIcon} alt="Image" className="img-fluid icon-feature" />
                    </div>
                    <h5 className="mt-4 mb-3"><Emoji symbol="🦺" label="vest"/>All equipment provided free of charge</h5>
                    <p className="mb-0">Must wear the lifejacket at ALL times or your ride will end immediately with no refund, it's the law. <Emoji symbol="❌" label="cross"/></p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 mb-8 mb-lg-0">
                    <div className="px-4 py-7 rounded hover-translate bg-primary-soft">
                    <div>

                    <img src={carIcon} alt="Image" className="img-fluid icon-feature" />

                    </div>
                    <h5 className="mt-4 mb-3"><Emoji symbol="🚗" label="car"/>Picking up and dropping off guests to your house available!</h5>
                    {/* grid of locations with different places and links to google */}
                    <p className="mb-0">$3.50 per mile or you can come to me to avoid this fee.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 mb-8 mb-lg-0">
                    <div className="px-4 py-7 rounded hover-translate bg-primary-soft">
                    <div>

                    <img src={droneIcon} alt="Image" className="img-fluid icon-feature" />

                    </div>
                    <h5 className="mt-4 mb-3"><Emoji symbol="📸" label="picture"/>Get professional aerial photos/video</h5>
                    {/* grid of locations with different places and links to google */}
                    <p className="mb-0">Sent to your email via our DJI MAVIC PRO drone. <br /> 
                    $35 per hour</p>
                    <br /><br />
                    <p className="mb-0">Scuba diving (Coming soon) </p>
                    <p className="mb-0">Surfing boards (Coming soon)</p>
                    <p className="mb-0">Boat (Coming soon) </p>
                    <p className="mb-0">Paddle boarding (Coming soon) </p>


                    </div>
                </div>
                </div>
            </div>
            </section>
        );
    }
}

export default Feature;
