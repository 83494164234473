import React, { Component } from "react";
import Header3 from "../../layout/header/header3";
import Herosection1 from "../../widgets/herosection/herosection1";
import axios from 'axios';
import Feature from '../../widgets/featurebox/feature';
import About from '../../widgets/index/about';


class Index2 extends Component {
  constructor(props) {
    super(props);
    this.state = {formSubmit: false };
  }
  handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    // setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formspree.io/f/xrgrgynd", 
      data: new FormData(form),
    })
      .then((r) => {
        // handleServerResponse(true, "Thanks!", form);
        this.setState({formSubmit: true});
        console.log("");
      })
      .catch((r) => {
        console.log("");
        // handleServerResponse(false, r.response.data.error, form);
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        {/*hero section start*/}
        <About />
        <Herosection1 />
        {/*hero section end*/}
        {/*header start*/}
        <Header3 />
        {/*header end*/}
        {/*body content start*/}
        <Feature />
        <div className="page-content">
          {/*services start*/}
          <section className="custom-pb-3 bg-primary position-relative">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8">
                  <div>
                    <h2 className="text-white font-w-5">
                    {/* <h2 className="text-white font-w-5">
Contact me here:                    <p className="lead mb-0 text-light">
                      You can send me a message below and I'll get back at you to the email you use
                    </p>
                    <p className="lead mb-0 text-light">
You can also reach me directly at:                     </p> */}

Contact me here:                  </h2>
                    <p className="lead mb-0 text-light">
                    You can send us a message below and we'll get back at you at the email you type
                    </p>
                    <p className="lead mb-0 text-light">
                      Thanks for your business
                    </p>
                    <div className={this.state.formSubmit ? "disappear" : "mb-3" }>
                      <form onSubmit={this.handleOnSubmit} >
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="john@email.com"
                          name="email"
                        ></input>
                        <br />
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Full Name
                        </label>
                        <input 
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder="Name"
                        name="name"
                        ></input>
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Date of Birth
                        </label>
                        <input type="text"
                        className="form-control"
                        name="date-of-birth"
                        placeholder="mm/dd/yyyy"
                        >
                          
                          </input>
                          <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                          name=""
                        >
                          Write the dates/times interested in renting
                        </label>
                          <input 
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder="Dates and times you want to check availability on"
                        name="dates-and-times"
                        ></input>
                                            <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Number of jetskis needed:
                        </label>
                        <br />
                        
                         <input type="radio" id="1-jetski" name="number-of-jetskis" value="1-jetski">
                           </input>
  <label for="1-jetski"><h3>1</h3></label><br />
  <input type="radio" id="2-jetski" name="number-of-jetskis" value="2-jetski">
    </input>
  <label for="2-jetski"><h3>2</h3></label><br />

                        

                                                <br />
                                                <br />

                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </form>
                      <br />
                    </div>
                    <br />
                    <div className={this.state.formSubmit ? "mb-3" : "disappear" }>
                      <p className="lead mb-0 text-light">
Thank you for sending that to us, we will get in contact with you shortly</p>
                      </div>

                    {/* <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Example textarea
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* / .row */}
            </div>
            <div
              className="shape-1 bottom"
              style={{ height: "55px", overflow: "hidden" }}
            >
              <svg
                viewBox="0 0 500 150"
                preserveAspectRatio="none"
                style={{ height: "100%", width: "100%" }}
              >
                <path
                  d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                  style={{ stroke: "none", fill: "white" }}
                />
              </svg>
            </div>
          </section>
          {/* <section className="mt-n15 p-0"> */}
          {/* <div className="container">
              <FeatureServices  bgshadow="bg-white shadow" />
            </div> */}
          {/* </section> */}
          {/*services end*/}
          {/*counter start*/}
          {/* <section>
            <div className="container">
              <Counter1 />
            </div>
          </section> */}
          {/*counter end*/}
          {/*about start*/}
          {/* <section className="pt-0">
          <About1 />
          </section> */}
          {/*about end*/}
          {/*feature start*/}
          {/* <Skillbox /> */}
          {/*feature end*/}
          {/*team start*/}
          {/* <section> */}
          {/* <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    Bootsland Team
                        </span>
                    <h2 className="mt-3 font-w-6">Meet Our Team Of Expert</h2>
                    <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
                  </div>
                </div>
              </div> */}
          {/* / .row */}
          {/* <Team1 />
            </div>
          </section> */}
          {/*team end*/}
          {/*pricing start*/}
          {/* <section>
            <div className="container-fluid">
          <Pricingplan1 />
          </div>
          </section> */}
          {/*pricing end*/}
          {/*blog start*/}
          {/* <section>
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    Latest News
                        </span>
                    <h2 className="mt-3 font-w-6">From Our Blog List Latest Feed</h2>
                    <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
                  </div>
                </div>
              </div>
              {/* / .row */}
          {/* <Blog2 /> 
            </div>
          </section> */}
          {/*blog end*/}
        </div>
        {/*body content end*/}
      </div>
    );
  }
}

export default Index2;
